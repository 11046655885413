$('#enterModal').on('show.bs.modal', function (e) {

    $.ajax({
        url: "/login",
        success: function(response){
            $('#enterModal .modal-body').html(response.data.content);

            jQuery(function($){
                $("#SmsLoginForm_phone").mask("+7(999)9999999",{placeholder:" "});
            });
        }
    });
})

$(document).ready(function () {

    $(document).on("click", "a[href='/docks']", function (event) {
        if(isGuest){
            event.preventDefault();
            $('#enterModal').modal('show');
        }
    })


    $(document).on("click", '.send-sms', function (event) {
        event.preventDefault();
        $('.help-block.error').remove();
        var phone = $('#SmsLoginForm_phone').val();
        if (phone) {
            var data = {'phone': phone}
            data[yupeTokenName] = yupeToken
            $.ajax({
                type   : "POST",
                data   : data,
                url    : '/sendSms',
                success: function (response) {
                    if (response.result) {
                        $('#code_block').css("display", "block")
                        $('.send-sms-wrapper').css("display", "none")
                        $('.login_submit').css("display", "block")
                    } else if (response.data !== undefined && response.data.message !== undefined) {
                        $("#SmsLoginForm_phone")
                            .after('<div class="help-block error">' + response.data.message + '</div>')
                    }
                }
            })
        }
    })

    $(document).on("click", '#login-btn', function (event) {
        event.preventDefault();

        var data = $('#login-form').serialize();
        data[yupeTokenName] = yupeToken;

        $.ajax({
            type   : "POST",
            data   : data,
            url    : '/login',
            success: function (response) {
                if(response.result){
                    document.location.href = "/docks";
                }else{
                    $('#enterModal .modal-body').html(response.data.content);

                     $('#code_block').css("display", "block");
                     $('.send-sms-wrapper').css("display", "none");
                     $('.login_submit').css("display", "block");

                     jQuery(function($){
                            $("#SmsLoginForm_phone").mask("+7(999)9999999",{placeholder:" "});
                     });
                }
            }
        })
    })
})