var map;

function initMap() {

    map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: 55.772606, lng: 37.033807},
        zoom: 15,
        styles: [{"featureType":"all","elementType":"all","stylers":[{"saturation":-100},{"gamma":0.5}]}]
    });
    var marker = new google.maps.Marker({
                                            position: {lat: 55.772606, lng: 37.033807},
                                            map: map
                                        });

}